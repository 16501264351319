export default {
  'aboutKwiz': 'About Kwizz',
  'aboutKwiz.contact': 'If you have any questions or feature suggestions, please contact me at <a href="mailto:hello@emilcarlsson.se">hello@emilcarlsson.se</a>.',
  'aboutKwiz.long': 'Kwizz is a simple web app for creating and playing quizzes with colleagues and friends right in the browser. Built with <a href="https://vuejs.org/">Vue</a> and <a href="https://supabase.com/">Supabase</a>, hosted on <a href="https://vercel.com/">Vercel</a>, analytics by <a href="https://plausible.io/">Plausible</a> and icons by <a href="https://akveo.github.io/eva-icons/#/">Eva Icons</a>. Homepage illustration by <a href="https://www.vecteezy.com/members/yalis">Yalis</a>.',
  'aboutKwiz.short': 'Kwizz is a simple web app for creating and playing quizzes with colleagues and friends right in the browser &ndash; completely free.',
  'averageResult': 'Average result',
  'averageTime': 'Average time',
  'back': 'Back',
  'cancel': 'Cancel',
  'changeAvatar': 'Change your avatar',
  'close': 'Close',
  'continue': 'Continue',
  'copied': 'Copied',
  'copy': 'Copy',
  'createAccount': 'Create account',
  'createNewQuiz': 'Create new quiz',
  'cumulativeScore': 'Your cumulative score',
  'date': 'Date',
  'delete': 'Delete',
  'deletedAccount': 'Deleted account',
  'emailAddress': 'Email Address',
  'errorOccurred': 'An error occurred.',
  'hidePublicQuizzes': 'Hide public quizzes',
  'homepage.title': 'Let\'s Kwizz!',
  'homepage.subtitle': 'Play quizzes, earn points and get ranked on the leaderboard.',
  'imageSelector.noResult': 'No images were found matching the query <b>"{0}"</b>. Try to refine your search, or try <a href="https://www.google.com/search?tbm=isch&q={0}" target="_blank">Google</a>. 🧐',
  'language': 'Language',
  'leaderboard': 'Leaderboard',
  'leaderboard.subtitle': 'The cumulative score of completed quizzes',
  'login': 'Log in',
  'login.forgot': 'Glömt lösenordet?',
  'login.forgot.title': 'Reset your password',
  'login.forgot.subTitle': 'Enter your email address and we will send you a password reset link.',
  'login.forgot.sendPasswordResetEmail': 'Send password reset email',
  'login.forgot.resetEmailSent': 'Reset email sent',
  'login.forgot.goBackToLogin': 'Go back to Login',
  'login.forgot.error.emailRequired': 'The email address is required.',
  'login.forgot.error.userDoesNotExist': 'A user with that email does not exist.',
  'login.noAccount': `Don't have an account?`,
  'madeBy': 'Made by',
  'mediaType.audio': 'Audio',
  'mediaType.image': 'Image',
  'mediaType.text': 'Text',
  'mediaType.video': 'Video',
  'mustBeUnique': 'Must be unique',
  'myQuizzes': 'My Quizzes',
  'myQuizzes.completedBy': 'Completed by {0} people',
  'myQuizzes.notice.noQuizzes': 'You have not created any quizzes yet, <router-link to="/builder">create your first quiz</router-link>! 🧐',
  'myQuizzes.question': 'question',
  'myQuizzes.questions': 'questions',
  'name': 'Name',
  'otherQuizzes': 'Other quizzes',
  'password': 'Password',
  'passwordLength': 'At least 6 characters',
  'pexels.copyright': 'Photos from <a href="https://www.pexels.com/">Pexels</a>. Not finding what you\'re looking for? Try <a href="https://www.google.com/search?tbm=isch&q={0}" target="_blank">Google</a> instead.',
  'play': 'Play',
  'play.subtitle': 'Below are the quizzes that you have not yet played',
  'players': 'Players',
  'playersWithCorrectAnswer': 'Players with correct answer',
  'privacy': 'Privacy',
  'quiz.404': 'Error 404',
  'quiz.404.first': 'Could not find a quiz with ID',
  'quiz.404.second': 'Are you sure it is the correct ID?',
  'quiz.addQuestion': 'Add question',
  'quiz.analytics': 'Analytics',
  'quiz.audioUrl': 'Audio URL',
  'quiz.audioUrl.notice': 'Only YouTube videos are supported',
  'quiz.audioUrl.placeholder': 'E.g. https://www.youtube.com/watch?v=dQw4w9WgXcQ',
  'quiz.chooseImage': 'Choose image',
  'quiz.correct': 'Correct',
  'quiz.correctAnswer': 'Correct answer',
  'quiz.cover_url': 'Cover Image',
  'quiz.cover_url.placeholder': 'E.g. https://via.placeholder.com/350x150.jpg',
  'quiz.cover_url.preview': 'Preview cover image',
  'quiz.created': 'Created',
  'quiz.defaultTitle': 'My Quiz!',
  'quiz.deleteQuiz.button': 'Delete',
  'quiz.deleteQuiz.description': 'Once you delete a quiz, there is no going back.',
  'quiz.deleteQuiz.label': 'Delete quiz',
  'quiz.deleteQuestion': 'Delete question',
  'quiz.description': 'Quiz Description',
  'quiz.description.placeholder': 'A description of the quiz...',
  'quiz.editQuiz': 'Edit your quiz in the builder',
  'quiz.error.characterMaxLength': 'The length of the text has exceeded the maximum length allowed.',
  'quiz.finish': 'Finish',
  'quiz.hideQuestions': 'Hide questions',
  'quiz.id': 'Quiz ID',
  'quiz.id.placeholder': 'Quiz ID',
  'quiz.imageUrl': 'Image URL',
  'quiz.imageUrl.notice': 'URL must contain file type (".jpg", ".png", ".gif", etc.)',
  'quiz.imageUrl.placeholder': 'E.g. https://via.placeholder.com/350x150.jpg',
  'quiz.incorrect': 'Incorrect',
  'quiz.maxCharacterLength': 'Maximum amount of allowed characters',
  'quiz.next': 'Next',
  'quiz.notice.cantPlayOwnQuiz': `You can't play your own quizzes, silly 🤭`,
  'quiz.notice.notMadeByYou': 'This quiz is not made by you, and you can therefore not view or edit it.',
  'quiz.notice.privacyPrivate': 'This quiz has its privacy set to <b>Private</b> and can not be played.',
  'quiz.notice.questionsLockedOncePlayed': `After a user has completed the quiz, it's not possible to edit the questions`,
  'quiz.previewQuestion': 'Preview question',
  'quiz.previous': 'Previous',
  'quiz.privacy': 'Privacy',
  'quiz.privacy.tooltip': 'The quiz is only playable when set to <b>Public</b>.',
  'quiz.privacy.private': 'Private',
  'quiz.privacy.public': 'Public',
  'quiz.qidAlreadyExists': 'A quiz with this ID already exists.',
  'quiz.question': 'Question',
  'quiz.questions': 'Questions',
  'quiz.showQuestions': 'Show questions',
  'quiz.successfullyDeleted': 'Quiz was successfully deleted.',
  'quiz.start': 'Start Quiz',
  'quiz.title': 'Quiz Title',
  'quiz.title.placeholder': 'Quiz Title',
  'quiz.uploadImage': 'Upload',
  'quiz.videoUrl': 'Video URL',
  'quiz.videoUrl.notice': 'Only YouTube videos are supported',
  'quiz.videoUrl.placeholder': 'E.g. https://www.youtube.com/watch?v=dQw4w9WgXcQ',
  'quiz.wrongAnswer': 'Wrong answer',
  'quiz.yourAnswer': 'Your answer',
  'quizCompleted': 'quiz completed',
  'quizCreated': 'quiz created',
  'quizzesCompleted': 'quizzes completed',
  'quizzesCreated': 'quizzes created',
  'quizHistory': 'Quiz History',
  'quizHistory.notice.noQuizzes.myProfile': 'You have not completed any quizzes yet, <router-link to="/play">play your first quiz</router-link>! 🤗',
  'quizHistory.notice.noQuizzes.otherProfile': 'This user has not completed any quizzes yet. 🤓',
  'quizHistory.notice.noQuizzes.playedAll': 'You have completed all available quizzes! 👏',
  'quizHistory.notice.noQuizzes.playedAll.createOne': 'Create your own quiz',
  'registerAccount': 'Register Account',
  'registerAccount.agreeTerms': 'I have read and agree to the ',
  'registerAccount.agreeTerms.link': 'Terms and Conditions',
  'registerAccount.alreadyHaveAccount': 'Already have an account?',
  'registerAccount.register': 'Register',
  'registerAccount.error.emptyEmail': 'An email address is required.',
  'registerAccount.error.emptyName': 'A name is required.',
  'registerAccount.error.emptyPassword': 'A password is required.',
  'registerAccount.error.emptyUsername': 'An username is required.',
  'registerAccount.error.passwordTooShort': 'Password must be at least 6 characters.',
  'registerAccount.error.usernameAlreadyExists': 'The username is taken.',
  'result': 'Result',
  'search': 'Search...',
  'select': 'Select',
  'score': 'Score',
  'showLess': 'Show less',
  'showMore': 'Show more',
  'settings': 'Settings',
  'settings.changePassword': 'Change password',
  'settings.confirmPassword': 'Confirm password',
  'settings.deleteAccount': 'Delete your account',
  'settings.deleteAccount.confirm': 'I confirm that I want to start the account deletion process for the account',
  'settings.deleteAccount.subtitle': 'This action will queue the removal of your account data.',
  'settings.error.emptyName': 'Name can not be empty.',
  'settings.error.emptyEmail': 'Email address can not be empty.',
  'settings.error.emptyUsername': 'Username can not be empty.',
  'settings.error.passwordsDontMatch': 'The given passwords do not match.',
  'settings.error.usernameOccupied': 'The username is already in use by another account.',
  'settings.subtitle': 'The settings for your account',
  'signOut': 'Sign out',
  'sortBy': 'Sort by',
  'terms': 'Terms',
  'time': 'Time',
  'upload': 'Upload',
  'username': 'Username',
  'version': 'Version',
  'welcomeBack': 'Welcome back!',
  'you': 'you',
}