<template>
	<div class="score" :style="{ cursor: clickable ? 'pointer' : 'default' }">
		<Icon name="star" class="orange" width="20" />
		<span>{{ override !== undefined ? override : score }}</span>
	</div>
</template>

<script>
import { auth, db } from '@/lib/supabase'
import { handleError } from '@/utils/utils'
import Icon from './Icon.vue'

export default {
	components: { Icon },

	props: {
		override: {
			type: Number,
		},
		clickable: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			score: 0,
		}
	},

	async beforeMount() {
		if (auth.user() && this.override === undefined) {
			const { data: score, error } = await db.rpc('get_user_score', {
				user_id_param: auth.user().id,
			})
			if (error) handleError(error)
			else this.score = score
		}
	},
}
</script>

<style lang="scss" scoped>
.score {
	align-items: center;
	display: inline-flex;
	font-weight: 600;

	::v-deep .icon i {
		margin-right: 6px;
	}
}
</style>
