<template>
	<div class="icon">
		<unicon
			v-if="type === 'unicon'"
			:name="name"
			:width="width"
			:height="height"
			:fill="fill"
			:hover-fill="hover"
			:icon-style="iconStyle"
		></unicon>

		<eva-icon
			v-if="type === 'eva-icon'"
			v-tippy="{ onShow: () => vtippy }"
			:name="name"
			:fill="fill"
			:width="width"
			:height="height"
			:content="content || null"
		></eva-icon>
	</div>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			default: 'eva-icon',
		},
		width: {
			type: String,
			default: '24',
		},
		height: {
			type: String,
			default: '24',
		},
		fill: {
			type: String,
			default: 'black',
		},
		hoverFill: {
			type: String,
		},
		iconStyle: {
			type: String,
			default: 'line',
		},
		vtippy: {
			type: Boolean,
			default: false,
		},
		content: {
			type: String,
		},
	},

	computed: {
		hover() {
			return this.hoverFill || this.fill
		},
	},
}
</script>

<style lang="scss">
.unicon {
	align-items: center;
	display: inline-flex !important;
}
</style>
