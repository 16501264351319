<template>
	<div id="app" :style="{ overflow: showNav ? 'hidden' : null }">
		<Header />

		<notifications width="400">
			<template slot="body" slot-scope="{ item, close }">
				<div :class="['notification', item.type]">
					<div>
						<div v-if="item.data && item.data.icon" class="icon">
							<Icon :name="item.data.icon" width="24" />
						</div>
						<div class="content">
							<p class="title">{{ item.title }}</p>
						</div>
						<button class="close" @click="close">
							<Icon name="close" width="18" />
						</button>
					</div>
					<div v-if="item.text">
						<div class="text" v-html="item.text"></div>
					</div>
				</div>
			</template>
		</notifications>

		<main>
			<div class="container">
				<Transition name="router" mode="out-in">
					<router-view :key="$route.fullPath" />
				</Transition>
			</div>
		</main>

		<Footer v-if="$route.name !== 'Quiz' || showNav" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { auth, db } from '@/lib/supabase'
import { handleError } from '@/utils/utils'

import Footer from '@/components/layout/Footer'
import Header from '@/components/layout/Header'
import Icon from './components/Icon.vue'

export default {
	components: {
		Footer,
		Header,
		Icon,
	},

	computed: {
		...mapGetters(['showNav']),
	},

	async beforeCreate() {
		await this.$store.dispatch('getLocalStorage')
		const { data: users, error } = await db.from('users').select('*')
		if (error) handleError(error)
		await this.$store.dispatch('setUsers', users)

		if (auth.session()) {
			let user = await this.getUser(auth.user().id)
			await this.$store.dispatch('setUser', user)
		}

		auth.onAuthStateChange(async (event, session) => {
			if (session) {
				let user = await this.getUser(session.user.id)
				await this.$store.dispatch('setUser', user)
			} else {
				await this.$store.dispatch('setUser', null)
			}

			if (event === 'PASSWORD_RECOVERY') {
				this.$router.push('settings')
			}
		})
	},

	methods: {
		async getUser(id) {
			const { data: user, error } = await db
				.from('users')
				.select('*')
				.eq('id', id)
				.limit(1)

			if (error) handleError(error)
			else return user[0]
		},
	},
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

#app {
	display: flex;
	flex-direction: column;
	height: 100%;
}

main {
	flex-grow: 1;
	padding: 5vh 0 50px !important;
	position: relative;

	@include mobile {
		padding: 0 0 50px !important;
	}
}

#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}

.router-enter-active,
.router-leave-active {
	transition: 0.5s ease;
}
.router-enter-from,
.router-leave-to {
	opacity: 0;
}
</style>
