import Vue from 'vue'
import { translate } from '../i18n/i18n'

const formatTime = (ms) => {
	let days, hours, minutes, seconds

	seconds = Math.floor(ms / 1000)
	minutes = Math.floor(seconds / 60)
	seconds = seconds % 60
	hours = Math.floor(minutes / 60)
	minutes = minutes % 60
	days = Math.floor(hours / 24)
	hours = hours % 24

	if (days > 0) {
		return `${days < 10 ? `0${days}` : days}:${hours < 10 ? `0${hours}` : hours}:${
			minutes < 10 ? `0${minutes}` : minutes
		}:${seconds < 10 ? `0${seconds}` : seconds}`
	} else if (hours > 0) {
		return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
			seconds < 10 ? `0${seconds}` : seconds
		}`
	} else {
		return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
	}
}

const shuffleArray = (array) => {
	var currentIndex = array.length,
		temporaryValue,
		randomIndex

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex)
		currentIndex -= 1

		// And swap it with the current element.
		temporaryValue = array[currentIndex]
		array[currentIndex] = array[randomIndex]
		array[randomIndex] = temporaryValue
	}

	return array
}

const copyToClipboard = (string) => {
	const el = document.createElement('textarea')
	el.value = string
	el.setAttribute('readonly', '')
	el.style.position = 'absolute'
	el.style.left = '-9999px'
	document.body.appendChild(el)
	el.select()
	document.execCommand('copy')
	document.body.removeChild(el)
}

const average = (array) => array.reduce((p, c) => p + c, 0) / array.length

const handleError = (error) => {
	console.log({ error })
	Vue.notify({
		title: translate('errorOccurred'),
		text: error.message,
		data: { icon: 'alert-triangle' },
		type: 'error',
		duration: -1,
	})
}

export { formatTime, shuffleArray, copyToClipboard, average, handleError }
