<template>
	<header>
		<Transition name="fade-slide-down" appear>
			<div class="container">
				<div>
					<router-link id="logo" to="/">
						<Icon name="question-mark-circle" />
						<span>Kwizz</span>
					</router-link>

					<div class="label clickable" @click="$router.push('/changelog')">
						v{{ currentVersion }}
					</div>
				</div>

				<Icon name="menu" class="nav-open-icon" @click="$store.commit('toggleNav', !showNav)" />

				<div :class="['nav', { show: showNav }]">
					<Icon name="close" class="nav-close-icon" @click="$store.commit('toggleNav', !showNav)" />

					<nav v-if="user">
						<router-link to="/play">{{ translate('play') }}</router-link>
						<router-link to="/leaderboard">{{ translate('leaderboard') }}</router-link>
						<router-link to="/about" class="mobile-only">{{ translate('aboutKwiz') }}</router-link>
						<User :user="user" :clickable="true" />
						<Score v-tippy :content="translate('cumulativeScore')" />
					</nav>

					<nav v-if="!user">
						<router-link to="/about">{{ translate('aboutKwiz') }}</router-link>
						<router-link to="/login" class="button">{{ translate('login') }}</router-link>
					</nav>
				</div>
			</div>
		</Transition>
	</header>
</template>

<script>
import { mapGetters } from 'vuex'
import Changelog from '../../../changelog.json'
import User from '@/components/User.vue'
import Score from '@/components/Score.vue'
import Icon from '../Icon.vue'

export default {
	components: {
		User,
		Score,
		Icon,
	},

	computed: {
		...mapGetters(['user', 'showNav']),

		currentVersion() {
			let latestDate = Object.keys(Changelog)[0]
			return Changelog[latestDate].version
		},
	},
}
</script>

<style lang="scss" scoped>
header {
	background: white;
	padding: 25px 0;
	position: relative;
	z-index: 100;

	> .container {
		align-items: center;
		display: flex;
		justify-content: space-between;

		> div {
			align-items: center;
			display: flex;
			position: relative;
			z-index: 2;

			&.nav {
				@include mobile {
					background: white;
					display: block;
					left: 0;
					height: calc(100vh - 65px);
					opacity: 0;
					padding: 125px 25px 25px;
					pointer-events: none;
					position: absolute;
					top: 0;
					transition: $transition;
					width: 100%;
					z-index: 1;

					&.show {
						opacity: 1;
						pointer-events: auto;

						.nav-close-icon {
							display: block;
						}
					}

					nav {
						align-items: flex-start;
						flex-direction: column;
						height: 100%;
						justify-content: flex-start;
					}
				}
			}
		}
	}

	.nav-open-icon {
		display: none !important;

		@include mobile {
			display: block !important;
		}
	}

	.nav-close-icon {
		display: none;
		position: absolute;
		right: 5%;
		top: 26px;
	}

	a {
		color: rgba($font-color, 0.75);
		font-weight: 600;
		text-decoration: none;

		@include mobile {
			color: $font-color;
			font-size: 24px;

			&.mobile-only {
				display: block !important;
			}
		}

		&:not(#logo) {
			border-radius: 5px;
			padding: 8px;

			&:hover {
				background: darken(white, 5%);
			}
		}

		&.mobile-only {
			color: rgba($font-color, 0.5);
			display: none;
			font-size: 20px;
		}

		&:hover {
			color: $font-color;
			text-decoration: none;
		}
	}

	#logo {
		align-items: center;
		color: $font-color;
		display: flex;

		::v-deep .icon i svg {
			fill: $primary-color;
		}

		span {
			font-size: 22px;
			font-weight: 700;
			margin-left: 8px;

			@include mobile {
				font-size: 19px;
			}
		}
	}

	.label {
		margin-left: 10px;
		position: relative;
		top: 0;

		&:hover {
			background: darken(white, 12.5%);
		}
	}

	nav a + a,
	nav a + button {
		margin-left: 40px;

		@include mobile {
			margin-top: 30px;
		}
	}

	nav {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	.score {
		align-items: center;
		display: inline-flex;
		cursor: default;
		font-weight: 600;
		margin-left: 25px;

		@include mobile {
			bottom: 25px;
			position: absolute;
			right: 25px;
		}

		::v-deep .icon i {
			margin-right: 6px;
			position: relative;
			top: 1px;
		}
	}

	::v-deep .user {
		margin-left: 40px;

		@include mobile {
			margin-top: auto;

			.avatar {
				$size: 26px;

				border-radius: $size !important;
				height: $size !important;
				width: $size !important;
			}

			.display-name {
				color: $font-color !important;
				font-size: 18px;
			}
		}

		&:hover .display-name {
			color: $font-color;
		}

		.display-name {
			color: rgba($font-color, 0.75);
			font-weight: 600;
		}
	}

	nav a + a,
	nav a + button,
	::v-deep .user,
	.score {
		@include mobile {
			margin-left: 0;
		}
	}
}

.fade-slide-down-enter-active,
.fade-slide-down-leave-active {
	transition-delay: 0.5s;
}
</style>
