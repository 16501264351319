import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import EvaIcons from 'vue-eva-icons'
Vue.use(EvaIcons)

import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'
import unicons from './utils/unicons'
Unicon.add(unicons)
Vue.use(Unicon)

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
Vue.use(VuePlyr, {
	plyr: {
		controls: ['play-large', 'play', 'progress', 'current-time', 'volume', 'fullscreen'],
		volume: 0.5,
		youtube: {
			controls: 0,
			playsinline: true,
		},
	},
})

import VueTippy, { TippyComponent } from 'vue-tippy'
Vue.use(VueTippy, {
	arrow: true,
	arrowType: 'round',
})
Vue.component('tippy', TippyComponent)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import { translate } from './i18n/i18n'
Vue.mixin({ methods: { translate } })

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
