import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '../views/Home.vue'

import { auth } from '@/lib/supabase'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
	},
	{
		path: '/login/forgot',
		name: 'ForgotPassword',
		component: () =>
			import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue'),
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
	},
	{
		path: '/leaderboard',
		name: 'Leaderboard',
		component: () => import(/* webpackChunkName: "leaderboard" */ '../views/Leaderboard.vue'),
	},
	{
		path: '/profile/:username',
		name: 'Profile',
		component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/Settings',
		name: 'Settings',
		component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/builder/:id?',
		name: 'Builder',
		component: () => import(/* webpackChunkName: "builder" */ '../views/Builder.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/q/:id',
		name: 'Quiz',
		component: () => import(/* webpackChunkName: "quiz" */ '../views/Quiz.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/play',
		name: 'Play',
		component: () => import(/* webpackChunkName: "play" */ '../views/Play.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/about',
		name: 'About',
		component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
	},
	{
		path: '/changelog',
		name: 'Changelog',
		component: () => import(/* webpackChunkName: "changelog" */ '../views/Changelog.vue'),
	},
	{
		path: '/privacy',
		name: 'Privacy',
		component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
	},
	{
		path: '/terms',
		name: 'Terms',
		component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		return savedPosition ? savedPosition : { x: 0, y: 0 }
	},
})

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
	const isAuthenticated = auth.user()

	if (store.getters.showNav) {
		store.commit('toggleNav', false)
	}

	if (requiresAuth && !isAuthenticated) {
		next({ path: '/login', query: { redirect: to.fullPath } })
	} else {
		next()
	}
})

export default router
