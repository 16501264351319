import { render, staticRenderFns } from "./QuizSuggestions.vue?vue&type=template&id=7395fdd0&scoped=true&"
import script from "./QuizSuggestions.vue?vue&type=script&lang=js&"
export * from "./QuizSuggestions.vue?vue&type=script&lang=js&"
import style0 from "./QuizSuggestions.vue?vue&type=style&index=0&id=7395fdd0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7395fdd0",
  null
  
)

export default component.exports