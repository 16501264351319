import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const token = 'kwizz'

export default new Vuex.Store({
	state: {
		user: null,

		users: [],

		colors: ['green', 'red', 'yellow', 'orange', 'pink', 'purple', 'blue', 'gray', 'black'],

		showNav: false,

		locale: 'sv-SE',

		availableLocales: [
			{ code: 'en-US', name: 'English' },
			{ code: 'sv-SE', name: 'Svenska' },
		],

		hidePublicQuizzes: false,

		environment: process.env.NODE_ENV,
	},
	mutations: {
		setUser(state, payload) {
			state.user = payload
		},

		setUsers(state, payload) {
			state.users = payload
		},

		updateHidePublicQuizzes(state, payload) {
			state.hidePublicQuizzes = payload
		},

		toggleNav(state, payload) {
			state.showNav = payload
		},

		setState(state, payload) {
			if (payload) {
				for (const [key, value] of Object.entries(payload)) {
					state[key] = value
				}
			}
		},
	},
	getters: {
		user: (state) => state.user,
		users: (state) => state.users,
		getUserById: (state) => (id) => state.users.find((user) => user.id === id),
		colors: (state) => state.colors,
		showNav: (state) => state.showNav,
		locale: (state) => state.locale,
		availableLocales: (state) => state.availableLocales,
		hidePublicQuizzes: (state) => state.hidePublicQuizzes,
		environment: (state) => state.environment,
		isAdmin: (state) => state.user.admin,
	},
	actions: {
		setUser(context, payload) {
			context.commit('setUser', payload)
		},

		setUsers(context, payload) {
			context.commit('setUsers', payload)
		},

		getLocalStorage(context) {
			const storage = JSON.parse(localStorage.getItem(token))

			// Handle incorrect locale
			if (
				storage &&
				storage.locale &&
				!this.state.availableLocales.find((l) => l.code === storage.locale)
			) {
				storage.locale = 'en-US'
			}

			context.commit('setState', storage)
		},

		setLocalStorage() {
			localStorage.setItem(
				token,
				JSON.stringify({
					locale: this.state.locale,
					hidePublicQuizzes: this.state.hidePublicQuizzes,
				})
			)
		},
	},
	modules: {},
})
