<template>
	<div id="quiz-suggestions">
		<div v-if="quizzes" class="quizzes">
			<TransitionGroup appear @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter">
				<div
					v-for="(quiz, index) in quizzes"
					:key="quiz.id"
					class="quiz"
					:data-index="index"
					@click="$router.replace(`/q/${quiz.qid}`)">
					<transition name="fade">
						<div v-if="quiz.cover_url" class="cover-img">
							<img :src="quiz.cover_url" alt="Cover Image" />
						</div>
					</transition>
					<p class="date">{{ formatDate(quiz.published_at) }}</p>
					<User :id="quiz.created_by" :only-avatar="true" :clickable="false" />
					<p class="title">{{ quiz.title }}</p>
					<div class="action">
						<button class="small">
							{{ quiz.started ? translate('continue') : translate('play') }}
						</button>
					</div>
				</div>
			</TransitionGroup>
		</div>
	</div>
</template>

<script>
import { auth, db } from '@/lib/supabase'
import { handleError } from '@/utils/utils'
import gsap from 'gsap'

import User from '@/components/User.vue'

export default {
	components: { User },

	props: {
		prefetchedQuizzes: {
			type: Array,
		},

		amount: {
			type: Number,
			default: 6,
		},

		exclude: {
			type: Number,
		},

		transitionDelay: {
			type: Number,
			default: 0,
		},
	},

	data() {
		return {
			quizzes: [],
			order: 'date',
			showEmptyNotice: false,
		}
	},

	async mounted() {
		this.quizzes = await this.getQuizzes()
	},

	methods: {
		async getQuizzes() {
			let quizzes = []

			if (this.prefetchedQuizzes) {
				quizzes = this.prefetchedQuizzes
			} else {
				const { data, error } = await db.rpc('get_available_quizzes', {
					user_id_param: auth.user().id,
				})
				if (error) handleError(error)
				quizzes = data
			}

			if (this.exclude) {
				quizzes = quizzes.filter((quiz) => quiz.id !== this.exclude)
			}

			const shuffled = quizzes.sort(() => 0.5 - Math.random())

			return shuffled.slice(0, this.amount)
		},

		formatDate(timestamp) {
			return new Date(timestamp).toLocaleDateString(this.$store.getters.locale, {
				month: 'short',
				day: 'numeric',
			})
		},

		beforeEnter(el) {
			el.style.opacity = 0
			el.style.transform = 'translateY(30px)'
		},

		enter(el, done) {
			gsap.to(el, {
				opacity: 1,
				y: 0,
				duration: 0.8,
				onComplete: done,
				delay: this.transitionDelay + el.dataset.index * 0.1,
			})
		},

		afterEnter(el) {
			el.style.transition = '.25s ease'
		},
	},
}
</script>

<style lang="scss" scoped>
#quiz-suggestions {
	.quizzes {
		.quiz {
			align-items: center;
			background: darken(white, 5%);
			border-radius: $border-radius;
			cursor: pointer;
			display: flex;
			padding: 20px 30px;
			position: relative;

			@include mobile {
				flex-wrap: wrap;
				padding: 10px 15px;

				.title {
					font-size: 16px;
					margin-top: 5px;
					padding-bottom: 5px;
					order: 4;
				}
			}

			&:hover {
				background: darken(white, 7.5%);
				transform: translateY(-2.5%) !important;
			}

			+ .quiz {
				margin-top: 6px;
			}

			.cover-img {
				height: 100%;
				position: absolute;
				right: 0;
				top: 0;
				width: 80%;
				z-index: 0;

				&::after {
					background: linear-gradient(to right, darken(white, 5%) 10%, transparent);
					content: '';
					display: block;
					height: 100%;
					position: absolute;
					right: 0;
					top: 0;
					transition: $transition;
					width: 100%;
				}

				img {
					border-radius: $border-radius;
					height: 100%;
					opacity: 0.5;
					object-fit: cover;
					width: 100%;
				}
			}

			.date {
				font-weight: 600;
				position: relative;
				text-align: left;
				width: 70px;
				z-index: 1;
			}

			::v-deep.user {
				margin-right: 12px;

				.avatar {
					cursor: pointer !important;
				}
			}

			.title {
				position: relative;
				z-index: 1;
			}

			.action {
				margin-left: auto;
				position: relative;
				z-index: 1;
			}
		}
	}
}
</style>
