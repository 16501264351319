<template>
	<div
		:class="['user', { clickable: clickable && userObject.username }]"
		@click="clickable && userObject.username ? navigateToProfile() : null"
	>
		<Avatar
			v-tippy
			:user="userObject"
			:clickable="clickable && !!userObject.username"
			:content="userObject.display_name || translate('deletedAccount')"
			@show="() => onlyAvatar"
		/>
		<p v-if="!onlyAvatar" class="display-name" :class="{ deleted: !userObject.display_name }">
			{{ userObject.display_name || translate('deletedAccount') }}
		</p>
	</div>
</template>

<script>
import Avatar from '@/components/Avatar.vue'

export default {
	components: { Avatar },

	props: {
		user: { type: Object },
		id: { type: String },
		clickable: { type: Boolean, default: false },
		onlyAvatar: { type: Boolean, default: false },
	},

	data() {
		return {
			userObject: this.user,
		}
	},

	async beforeMount() {
		if (!this.userObject && this.id) {
			this.userObject = this.$store.getters.getUserById(this.id)
		}

		if (!this.userObject && !this.id) {
			this.userObject = {}
		}
	},

	methods: {
		navigateToProfile() {
			if (this.$route.path !== `/profile/@${this.userObject.username}`) {
				this.$router.push(`/profile/@${this.userObject.username}`)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.user {
	align-items: center;
	cursor: default;
	display: inline-flex;

	&.clickable {
		cursor: pointer;
	}

	.avatar {
		$size: 22px;

		border-radius: $size;
		height: $size;
		margin-right: 6px;
		width: $size;
	}

	.display-name {
		font-weight: 500;
		transition: $transition;

		&.deleted {
			opacity: 0.5;
			font-style: italic;
		}
	}
}
</style>
