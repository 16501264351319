<template>
	<div class="home">
		<div class="hero">
			<Transition name="fade-slide-up" appear>
				<div class="text">
					<h1>
						{{ translate('homepage.title') }}
					</h1>
					<p class="subtitle">
						{{ translate('homepage.subtitle') }}
					</p>
					<p v-html="translate('aboutKwiz.short')"></p>

					<div class="buttons">
						<button v-if="!loggedIn" class="big" @click="$router.push('/login')">
							{{ translate('login') }}
						</button>
						<button v-if="!loggedIn" class="secondary big" @click="$router.push('/register')">
							{{ translate('createAccount') }}
						</button>
						<button v-if="loggedIn" class="big icon-right" @click="$router.push('/play')">
							{{ translate('play') }}<Icon name="arrow-forward" fill="white" />
						</button>
					</div>
				</div>
			</Transition>

			<Transition name="fade" appear>
				<div class="image">
					<img src="@/assets/illustration.svg" alt="Illustration" />
				</div>
			</Transition>
		</div>

		<QuizSuggestions :transition-delay="0.5" />
	</div>
</template>

<script>
import { auth } from '@/lib/supabase'

import QuizSuggestions from '../components/QuizSuggestions.vue'
import Icon from '../components/Icon.vue'

export default {
	components: {
		QuizSuggestions,
		Icon,
	},

	computed: {
		loggedIn() {
			return !!auth.user()
		},
	},
}
</script>

<style lang="scss" scoped>
.home {
	margin-top: 2.5vh;
	text-align: center;

	.hero {
		display: flex;

		@include mobile {
			align-items: center;
			display: flex;
			flex-direction: column;
		}

		.text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: left;
			width: 50%;

			@include mobile {
				width: 100%;
			}

			h1 {
				font-size: 40px;
			}

			.subtitle {
				font-size: 18px;
				margin-top: 15px;
				max-width: 450px;
			}

			p:not([class]) {
				color: lighten($font-color, 30%);
				font-size: 14px;
				padding-right: 20px;
				margin-top: 10px;
			}

			.buttons {
				margin-top: 50px;

				@include mobile {
					align-items: center;
					display: flex;
					flex-direction: column;
				}

				button {
					justify-content: center;
					width: 185px;

					@include mobile {
						padding: 16px;
						width: 100%;
					}

					+ button {
						margin-left: 25px;

						@include mobile {
							margin-left: 0;
							margin-top: 25px;
						}
					}
				}
			}
		}

		.image {
			width: 50%;

			@include mobile {
				margin-top: 50px;
				max-width: 500px;
				width: 80%;
			}

			img {
				width: 100%;
			}
		}
	}

	#quiz-suggestions {
		margin-top: 100px;
	}
}
</style>
