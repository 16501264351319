<template>
	<div
		class="avatar"
		:class="[{ hoverable: hover }, user.color]"
		:style="{
			backgroundImage: user.avatar_url ? `url(${user.avatar_url})` : null,
			backgroundSize: 'cover',
			backgroundPosition: 'center center',
			borderRadius: fullsize ? '9999px' : `${size}px`,
			cursor: clickable || hover ? 'pointer' : 'default',
			height: fullsize ? '100%' : `${size}px`,
			width: fullsize ? '100%' : `${size}px`,
		}">
		<div v-if="hover || loading" :class="{ hover, loading }">
			<slot></slot>
		</div>

		<span
			v-if="!user.avatar_url"
			:style="{
				fontSize: `${Math.floor(size / 2.5)}px`,
				transform: !user.display_name ? 'rotate(90deg) translateY(-10%)' : null,
			}"
			>{{ initials(user.display_name) }}</span
		>
	</div>
</template>

<script>
export default {
	props: {
		user: {
			type: Object,
			required: true,
		},

		clickable: {
			type: Boolean,
			default: false,
		},

		hover: {
			type: Boolean,
			default: false,
		},

		size: {
			type: Number,
			default: 22,
		},

		fullsize: {
			type: Boolean,
		},

		loading: {
			type: Boolean,
		},
	},

	methods: {
		initials(name) {
			return name
				? name
						.split(' ')
						.map((n) => n[0])
						.join('')
				: ':('
		},
	},
}
</script>

<style lang="scss" scoped>
.avatar {
	align-items: center;
	background: $gray;
	cursor: default;
	display: flex;
	font-size: 10px;
	font-weight: 600;
	justify-content: center;
	position: relative;

	&.hoverable:hover {
		.hover {
			opacity: 1;
		}
	}

	.hover {
		align-items: center;
		background: rgba(0, 0, 0, 0.5);
		border-radius: 9999px;
		color: white;
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		opacity: 0;
		position: absolute;
		top: 0;
		transition: $transition;
		width: 100%;
		z-index: 9;
	}

	.loading {
		align-items: center;
		background: rgba(white, 0.35);
		border-radius: 9999px;
		color: white;
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		position: absolute;
		top: 0;
		transition: $transition;
		width: 100%;
		z-index: 9;
	}

	span {
		color: white;
		opacity: 0.75;
		position: relative;
	}

	@each $color, $variable in $palette {
		&.#{'' + $color} {
			background: $variable;
		}
	}
}
</style>
