<template>
	<footer :class="{ mobileNav: showNav }">
		<Transition name="fade-slide-up" appear>
			<div class="container">
				<p>
					{{ translate('madeBy') }}
					<a href="https://emilcarlsson.se" target="_blank">Emil Carlsson</a>
				</p>

				<nav>
					<router-link to="/about" class="desktop-only">{{ translate('aboutKwiz') }}</router-link>
					<router-link to="/terms">{{ translate('terms') }}</router-link>
					<router-link to="/privacy">{{ translate('privacy') }}</router-link>

					<LanguageSelector />
				</nav>
			</div>
		</Transition>
	</footer>
</template>

<script>
import { mapGetters } from 'vuex'
import LanguageSelector from '@/components/LanguageSelector.vue'

export default {
	components: {
		LanguageSelector,
	},

	computed: {
		...mapGetters(['showNav']),
	},
}
</script>

<style lang="scss" scoped>
footer {
	opacity: 0.75;
	padding: 35px 0;
	position: relative;
	transition: $transition;
	z-index: 1;

	@include mobile {
		padding: 15px 0;
		z-index: 101;
	}

	&:hover {
		opacity: 1;
	}

	&.mobileNav {
		@include mobile {
			background: darken(white, 12.5%);
			bottom: 0;
			left: 0;
			opacity: 1;
			position: absolute;
			width: 100%;

			> .container {
				p {
					display: none;
				}

				nav {
					text-align: center;
					width: 100%;

					a {
						display: block;
						color: $font-color;
					}
				}
			}
		}
	}

	> .container {
		align-items: center;
		display: flex;
		justify-content: space-between;

		p {
			a {
				color: $font-color;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		nav {
			align-items: center;
			display: flex;

			@media (max-width: 599px) {
				text-align: center;
			}

			a {
				border-radius: 5px;
				color: rgba($font-color, 0.5);
				font-weight: 600;
				padding: 8px;
				text-decoration: none;

				@media (max-width: 599px) {
					display: none;
				}

				&:hover {
					background: darken(white, 5%);
					color: $font-color;
					text-decoration: none;
				}

				+ a {
					margin-left: 40px;
				}

				&:last-of-type {
					margin-right: 30px;
				}

				&.desktop-only {
					display: block;

					@include mobile {
						display: none;
					}
				}

				@include mobile {
					&:nth-child(2) {
						margin-left: 0;
					}
				}
			}

			::v-deep .language-selector {
				margin-left: auto;
			}
		}
	}
}

.fade-slide-up-enter-active,
.fade-slide-up-leave-active {
	transition-delay: 0.5s;
}
</style>
