<template>
	<div class="language-selector">
		<div v-tippy class="active" :content="translate('language')" @click="togglePopup()">
			<img :src="require(`@/assets/flags/${$store.getters.locale}.svg`)" alt="Language" />
		</div>

		<div :class="['popup', { open }]" class="popup">
			<div
				v-for="language in availableLocales"
				:key="language.code"
				:class="[{ active: locale === language.code }, 'option']"
				@click="changeLocale(language.code)"
			>
				<img :src="require(`@/assets/flags/${language.code}.svg`)" :alt="language.name" />
				<p>{{ language.name }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			open: false,
		}
	},

	computed: {
		...mapGetters(['locale', 'availableLocales']),
	},

	methods: {
		changeLocale(locale) {
			this.$store.state.locale = locale
			this.$store.dispatch('setLocalStorage')
		},

		togglePopup() {
			this.open = !this.open
		},
	},
}
</script>

<style lang="scss" scoped>
.language-selector {
	position: relative;

	.active {
		border-radius: $border-radius;
		cursor: pointer;
		padding: 4px 8px;
		transition: $transition;

		&:hover {
			background: darken(white, 7.5%);
		}
	}

	.popup {
		background: darken(white, 12.5%);
		border-radius: $border-radius;
		padding: 10px;
		pointer-events: none;
		position: absolute;
		right: 0;
		opacity: 0;
		top: 15px;
		transition: $transition;
		transform: translateY(-110%);
		z-index: 99;

		&::after {
			$size: 10px;
			bottom: 2px;
			border-top: $size solid darken(white, 12.5%);
			border-left: $size solid transparent;
			border-right: $size solid transparent;
			content: '';
			height: 0;
			position: absolute;
			right: 12px;
			transform: translateY(100%);
			width: 0;
		}

		&.open {
			opacity: 1;
			pointer-events: all;
			top: 0;
		}

		.option {
			align-items: center;
			border-radius: $border-radius;
			cursor: pointer;
			display: flex;
			padding: 4px 8px;
			transition: $transition;

			&:hover {
				background: darken(white, 20%);
			}

			+ .option {
				margin-top: 5px;
			}

			&.active {
				background: rgba($primary-color, 0.75);
				color: white;

				&:hover {
					background: rgba($primary-color, 1);
				}
			}

			img {
				margin-right: 5px;
			}
		}
	}

	img {
		width: 25px;
	}
}
</style>
