import store from '../store'

const i18n = (string, locale, interpolation) => {
	if (!locale) locale = store.getters.locale

	const data = require(`./locales/${locale}.js`).default

	if (!data[string]) return string

	return interpolation !== undefined ? data[string].replaceAll('{0}', interpolation) : data[string]
}

const translate = (string, locale, interpolation) => {
	return i18n(string, locale, interpolation)
}

export { translate }
