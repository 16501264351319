export default {
  'aboutKwiz': 'Om Kwizz',
  'aboutKwiz.contact': 'Om du har några frågor eller funktionsförslag, var vänlig kontakta mig på <a href="mailto:hello@emilcarlsson.se">hello@emilcarlsson.se</a>.',
  'aboutKwiz.long': 'Kwizz är en användarvänlig webbapplikation för att skapa och spela frågesporter direkt i webbläsaren. Byggd med <a href="https://vuejs.org/">Vue</a> och <a href="https://supabase.com/">Supabase</a>, lagrad på <a href="https://vercel.com/">Vercel</a>, webbanalys av <a href="https://plausible.io/">Plausible</a> och ikoner av <a href="https://akveo.github.io/eva-icons/#/">Eva Icons</a>. Illustration på startsidan av <a href="https://www.vecteezy.com/members/yalis">Yalis</a>.',
  'aboutKwiz.short': 'Kwizz är en användarvänlig webbapplikation för att skapa och spela frågesporter direkt i webbläsaren &ndash; helt gratis.',
  'averageResult': 'Genomsnittligt resultat',
  'averageTime': 'Genomsnittlig tid',
  'back': 'Tillbaka',
  'cancel': 'Avbryt',
  'changeAvatar': 'Byt din profilbild',
  'close': 'Stäng',
  'continue': 'Fortsätt',
  'copied': 'Kopierad',
  'copy': 'Kopiera',
  'createAccount': 'Skapa konto',
  'createNewQuiz': 'Skapa ny quiz',
  'cumulativeScore': 'Din sammanlagda poäng',
  'date': 'Datum',
  'delete': 'Radera',
  'deletedAccount': 'Borttaget konto',
  'emailAddress': 'E-post Adress',
  'errorOccurred': 'Ett fel uppstod.',
  'hidePublicQuizzes': 'Dölj publika quizzes',
  'homepage.title': 'Dags att Kwizza!',
  'homepage.subtitle': 'Spela quizzes, tjäna poäng och bli rankad på topplistan.',
  'imageSelector.noResult': 'Inga bilder hittades som matchar din sökning <b>"{0}"</b>. Försök att förfina sökningen, eller testa <a href="https://www.google.com/search?tbm=isch&q={0}" target="_blank">Google</a>. 🧐',
  'language': 'Språk',
  'leaderboard': 'Topplista',
  'leaderboard.subtitle': 'Den sammanlagda poängen för slutförda quizzes',
  'login': 'Logga in',
  'login.forgot': 'Glömt?',
  'login.forgot.title': 'Återställ ditt lösenord',
  'login.forgot.subTitle': 'Fyll i din epost adress så skickar vi en länk för återställning av lösenord.',
  'login.forgot.sendPasswordResetEmail': 'Skicka e-post för återställning av lösenord',
  'login.forgot.resetEmailSent': 'E-post för återställning skickat',
  'login.forgot.goBackToLogin': 'Gå tillbaka till Logga in',
  'login.forgot.error.emailRequired': 'E-post adress måste fyllas i.',
  'login.forgot.error.userDoesNotExist': 'En användare med den e-post adressen finns inte.',
  'login.noAccount': `Har du inget konto?`,
  'madeBy': 'Skapad av',
  'mediaType.audio': 'Ljud',
  'mediaType.image': 'Bild',
  'mediaType.text': 'Text',
  'mediaType.video': 'Video',
  'mustBeUnique': 'Måste vara unikt',
  'myQuizzes': 'Mina quizzes',
  'myQuizzes.completedBy': 'Slutförd av {0} personer',
  'myQuizzes.notice.noQuizzes': 'Du har inte skapat några quizzes än, <router-link to="/builder">skapa din första quiz</router-link>! 🧐',
  'myQuizzes.question': 'fråga',
  'myQuizzes.questions': 'frågor',
  'name': 'Namn',
  'otherQuizzes': 'Andra quizzes',
  'password': 'Lösenord',
  'passwordLength': 'Minst 6 tecken',
  'pexels.copyright': 'Bilder från <a href="https://www.pexels.com/">Pexels</a>. Hittar du inte vad du letar efter? Testa <a href="https://www.google.com/search?tbm=isch&q={0}" target="_blank">Google</a> istället.',
  'play': 'Spela',
  'play.subtitle': 'Nedan är alla quizzes du ännu inte spelat',
  'players': 'Spelare',
  'playersWithCorrectAnswer': 'Spelare som hade rätt svar',
  'privacy': 'Integritetspolicy',
  'quiz.404': 'Error 404',
  'quiz.404.first': 'Kunde inte hitta en quiz med ID',
  'quiz.404.second': 'Är du säker på att det är rätt ID?',
  'quiz.addQuestion': 'Lägg till fråga',
  'quiz.analytics': 'Statistik',
  'quiz.audioUrl': 'Ljud URL',
  'quiz.audioUrl.notice': 'Det finns bara stöd för YouTube videos',
  'quiz.audioUrl.placeholder': 'Ex. https://www.youtube.com/watch?v=dQw4w9WgXcQ',
  'quiz.chooseImage': 'Välj bild',
  'quiz.correct': 'Rätt',
  'quiz.correctAnswer': 'Rätt svar',
  'quiz.cover_url': 'Omslagsbild',
  'quiz.cover_url.placeholder': 'Ex. https://via.placeholder.com/350x150.jpg',
  'quiz.cover_url.preview': 'Förhandsgranska omslagsbild',
  'quiz.created': 'Skapad',
  'quiz.defaultTitle': 'Min Quiz!',
  'quiz.deleteQuiz.button': 'Radera',
  'quiz.deleteQuiz.description': 'När du väl har raderat en quiz finns det ingen återvändo.',
  'quiz.deleteQuiz.label': 'Radera quiz',
  'quiz.deleteQuestion': 'Radera fråga',
  'quiz.description': 'Quiz Beskrivning',
  'quiz.description.placeholder': 'En beskrivning av quizzen...',
  'quiz.editQuiz': 'Redigera din quiz',
  'quiz.error.characterMaxLength': 'Längden på texten har överskridit den tillåtna maxgränsen.',
  'quiz.finish': 'Slutför',
  'quiz.hideQuestions': 'Dölj frågor',
  'quiz.id': 'Quiz ID',
  'quiz.id.placeholder': 'Quiz ID',
  'quiz.imageUrl': 'Bild URL',
  'quiz.imageUrl.notice': 'URL måste innehålla filtyp (".jpg", ".png", ".gif", etc.)',
  'quiz.imageUrl.placeholder': 'Ex. https://via.placeholder.com/350x150.jpg',
  'quiz.incorrect': 'Fel',
  'quiz.maxCharacterLength': 'Max antal tillåtna tecken',
  'quiz.next': 'Nästa',
  'quiz.notice.cantPlayOwnQuiz': `Du kan ju inte spela din egen quiz, knäppis 🤭`,
  'quiz.notice.notMadeByYou': 'Den här quizzen är inte skapad av dig, och du kan därför inte se eller redigera den.',
  'quiz.notice.privacyPrivate': 'Den är quizzen har sin sekretessinställning inställd på <b>Privat</b> och kan ej spelas.',
  'quiz.notice.questionsLockedOncePlayed': 'När en användare har spelat quizzen så är det inte möjligt att ändra på frågorna.',
  'quiz.previewQuestion': 'Förhandsgranska fråga',
  'quiz.previous': 'Tillbaka',
  'quiz.privacy': 'Sekretess',
  'quiz.privacy.tooltip': 'Quizzen är bara spelbar när den är satt till <b>Publik</b>.',
  'quiz.privacy.private': 'Privat',
  'quiz.privacy.public': 'Publik',
  'quiz.qidAlreadyExists': 'Det finns redan en quiz med detta ID.',
  'quiz.question': 'Fråga',
  'quiz.questions': 'Frågor',
  'quiz.showQuestions': 'Visa frågor',
  'quiz.successfullyDeleted': 'Quizzen har raderats.',
  'quiz.start': 'Starta Quiz',
  'quiz.title': 'Quiz Titel',
  'quiz.title.placeholder': 'Quiz Titel',
  'quiz.uploadImage': 'Ladda upp',
  'quiz.videoUrl': 'Video URL',
  'quiz.videoUrl.notice': 'Det finns bara stöd för YouTube videos',
  'quiz.videoUrl.placeholder': 'Ex. https://www.youtube.com/watch?v=dQw4w9WgXcQ',
  'quiz.wrongAnswer': 'Fel svar',
  'quiz.yourAnswer': 'Ditt svar',
  'quizCompleted': 'quiz slutförd',
  'quizCreated': 'quiz skapad',
  'quizzesCompleted': 'quizzes slutförda',
  'quizzesCreated': 'quizzes skapade',
  'quizHistory': 'Quiz Historik',
  'quizHistory.notice.noQuizzes.myProfile': 'Du har inte spelat några quizzes än, <router-link to="/play">spela din första quiz</router-link>! 🤗',
  'quizHistory.notice.noQuizzes.otherProfile': 'Den här användaren har inte spelat några quizzes än. 🤓',
  'quizHistory.notice.noQuizzes.playedAll': 'Du har spelat alla tillgängliga quizzes! 👏',
  'quizHistory.notice.noQuizzes.playedAll.createOne': 'Skapa en egen quiz',
  'registerAccount': 'Registrera konto',
  'registerAccount.alreadyHaveAccount': 'Har du redan ett konto?',
  'registerAccount.agreeTerms': 'Jag har läst och godkänner ',
  'registerAccount.agreeTerms.link': 'villkoren',
  'registerAccount.register': 'Registrera',
  'registerAccount.error.emptyEmail': 'En e-post adress är obligatorisk.',
  'registerAccount.error.emptyName': 'Ett namn är obligatorisk.',
  'registerAccount.error.emptyPassword': 'Ett lösenord är obligatorisk.',
  'registerAccount.error.emptyUsername': 'Ett användarnamm är obligatorisk.',
  'registerAccount.error.passwordTooShort': 'Lösenordet måste vara minst 6 tecken.',
  'registerAccount.error.usernameAlreadyExists': 'Användarnamnet är upptaget.',
  'result': 'Resultat',
  'search': 'Sök...',
  'select': 'Välj',
  'score': 'Poäng',
  'showLess': 'Visa färre',
  'showMore': 'Visa fler',
  'settings': 'Inställningar',
  'settings.changePassword': 'Ändra lösenord',
  'settings.confirmPassword': 'Bekräfta lösenord',
  'settings.deleteAccount': 'Radera ditt konto',
  'settings.deleteAccount.subtitle': 'Den här åtgärden kommer ta bort all data kopplad till ditt konto.',
  'settings.deleteAccount.confirm': 'Jag bekräftar att jag vill starta borttagningsprocessen för kontot',
  'settings.error.emptyEmail': 'E-post adress måste fyllas i.',
  'settings.error.emptyName': 'Namn måste fyllas i.',
  'settings.error.emptyUsername': 'Användarnamn måste fyllas i.',
  'settings.error.passwordsDontMatch': 'Lösenorden du angett matchar inte.',
  'settings.error.usernameOccupied': 'Användarnamnet är upptaget.',
  'settings.subtitle': 'Inställningarna för ditt konto',
  'signOut': 'Logga ut',
  'sortBy': 'Sortera',
  'terms': 'Villkor',
  'time': 'Tid',
  'upload': 'Ladda upp',
  'username': 'Användarnamn',
  'version': 'Version',
  'welcomeBack': 'Välkommen tillbaka!',
  'you': 'dig',

  // Firebase Error Codes
  'auth/requires-recent-login': 'Denna åtgärd är känslig och kräver nyligen autentisering. Logga ut och in igen innan du försöker igen.',
  'auth/invalid-email': 'E-postadressen är inkorrekt formaterad.',
  'auth/weak-password': 'Lösenordet ska vara minst 6 tecken.'
}