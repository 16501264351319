var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"avatar",class:[{ hoverable: _vm.hover }, _vm.user.color],style:({
		backgroundImage: _vm.user.avatar_url ? `url(${_vm.user.avatar_url})` : null,
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		borderRadius: _vm.fullsize ? '9999px' : `${_vm.size}px`,
		cursor: _vm.clickable || _vm.hover ? 'pointer' : 'default',
		height: _vm.fullsize ? '100%' : `${_vm.size}px`,
		width: _vm.fullsize ? '100%' : `${_vm.size}px`,
	})},[(_vm.hover || _vm.loading)?_c('div',{class:{ hover: _vm.hover, loading: _vm.loading }},[_vm._t("default")],2):_vm._e(),(!_vm.user.avatar_url)?_c('span',{style:({
			fontSize: `${Math.floor(_vm.size / 2.5)}px`,
			transform: !_vm.user.display_name ? 'rotate(90deg) translateY(-10%)' : null,
		})},[_vm._v(_vm._s(_vm.initials(_vm.user.display_name)))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }